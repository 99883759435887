<template>
    <v-tooltip bottom color="primary" :disabled="isFocus || disableMask">
        <template v-slot:activator="{ on: onTooltip }">
            <v-card v-on="onTooltip"
                color="transparent"
                :disabled="isLoading"
                flat
                light
                :width="!!(isFocus || model || disableMask) ? disableMask ? '100%' : 400 : getDefaultSearchBarWidth()"
                class="transform ma-2 v-card-search"
                :class="{'v-card-search--active': !!(isFocus || model || disableMask)}"
                >
                <v-text-field
                    v-model="model"
                    ref="searchTermInput"
                    class="v-combobox--search v-combobox--outlined-variant elevation-1"
                    :class="{'rounded-circle': !isFocus && !model && !disableMask}"
                    hide-no-data
                    hide-details
                    :solo="!!(isFocus || model || disableMask)"
                    :outlined="!isFocus && !model && !disableMask"
                    flat
                    clearable
                    :readonly="readonly"
                    full-width
                    dense
                    :persistent-placeholder="autofocus"
                    :autofocus="autofocus"
                    :placeholder="placeholder"
                    @focus="isFocus = true"
                    @blur="isFocus = false"
                >
                    <template v-slot:label>
                        <div class="v-combobox--search-label">
                            <div v-if="isLoading" v-html="placeholderLoading" />
                            <div v-else v-html="isFocus ? placeholder : ''" />
                        </div>
                    </template>
                    <template v-slot:prepend-inner>
                        <v-icon v-text="'mdi-magnify'" :color="!!(isFocus || model || disableMask) ? 'primary' : 'white'" :right="!isFocus && !model && !disableMask" />
                    </template>
                </v-text-field>
            </v-card>
        </template>
        <span v-html="placeholder" />
    </v-tooltip>
</template>

<script>
import Debounceable from '@/components/Debounceable';
const DEFAULT_SEARCHBAR_WITH = 40;

export default {
    mixins: [Debounceable],
    props: {
        value: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: function () {
                return this.$t('vvtList.searchRecords.search')
            } 
        },
        placeholderLoading: {
            type: String,
            default: function () {
                return this.$t('vvtList.searchRecords.loading')
            } 
        },
        loading: {
            type: Boolean,
            default: false
        },
        disableMask: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isFocus: false
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    computed: {
        isLoading() {
            return this.loading || this.$wait.is('fetch records') || this.$wait.is('fetch companies') || this.$wait.is('fetch domains');
        }
    },
    methods: {
        resetFilter() {
            this.model = null;
            if (this.$refs.searchTermInput) {
                this.$refs.searchTermInput.focus();
            }
        },
        getDefaultSearchBarWidth () {
            return DEFAULT_SEARCHBAR_WITH;
        }
    }
};
</script>
